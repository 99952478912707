"use client";

import { Button } from "@/components/ui/button";
import { isSerializedJSON } from "@/lib/utils";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <main className="flex h-full flex-col justify-between bg-mavins-beige pt-4">
      <div className="overflow-auto">
        <div className="px-4">
          <h1
            className="font-franklinGothic text-title font-semibold uppercase
              text-mavins-grey"
          >
            Something went wrong!
          </h1>
          {!isSerializedJSON(error.message) ? (
            error.message.split("\n").map((line, index) => (
              <p
                key={index}
                className="mt-4 break-words text-lg font-medium leading-6
                  tracking-4 text-mavins-grey"
              >
                {line}
              </p>
            ))
          ) : (
            <pre
              className="mt-4 w-full whitespace-break-spaces text-sm font-medium
                leading-5 tracking-4 text-mavins-grey"
            >
              {error.message}
            </pre>
          )}
        </div>
      </div>
      <div className="sticky bottom-0 gap-4 bg-transparent p-4">
        <Button
          // Attempt to recover by trying to re-render the route
          onClick={reset}
          className="inline-flex h-[54px] w-full items-center justify-center
            rounded-lg bg-mavins-grey text-lg font-bold text-mavins-beige
            disabled:opacity-40"
        >
          Try again
        </Button>
      </div>
    </main>
  );
}
